import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import CalculatorHero from 'components/CalculatorPage/Hero';
import CalculatorLoanAmount from 'components/CalculatorPage/LoanAmount';
import CalculatorMaxMonthly from 'components/CalculatorPage/MaxMonthly';
import CalculatorRepayments from 'components/CalculatorPage/Repayments';

const Calculator: React.FC = () => {
  return (
    <Layout>
      <SEO title="Ireland's Best Mortgage Calculator" />
      <CalculatorHero />
      <CalculatorLoanAmount />
      <CalculatorMaxMonthly />
      <CalculatorRepayments />
    </Layout>
  );
};

export default Calculator;
