import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import FeatureSection from 'components/FeatureSection';

import { FeatureSectionText } from 'helpers/definitions';

const CalculatorRepayments: React.FC = () => {
  const { markdownRemark, file } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "calculator page repayments" } }) {
        html
        frontmatter {
          title
          content
          altImageText
        }
      }
      file(relativePath: { eq: "feature_calculator.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const featureText: FeatureSectionText = markdownRemark.frontmatter; 
  const img = file.childImageSharp.fluid;

  return (
    <FeatureSection
      title={featureText.title}
      content={markdownRemark.html}
      appImage={img}
      altImageText={featureText.altImageText}
      imageLeftOnDesktop={true}
      bggray={false}
    />
  );
};

export default CalculatorRepayments;
